import { Suspense } from "react";
import "./App.css";
import useSticky from "./hooks/useSticky.js";
import {
  Home,
  Kaszubska_Polana,
  Kontakt,
  Lokalizacja,
  Navbar,
  Plan,
} from "./containers";
//import cookies from "js-cookie";
//import classNames from "classnames";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

function App() {
  const { isSticky, element } = useSticky();
  const { t } = useTranslation(["app"]);

  return (
    <Suspense fallback={"Loading..."}>
      <div className="App">
        <Navbar sticky={isSticky} />
        <Home element={element} />
        <Kaszubska_Polana element={element} />

        <Lokalizacja element={element} />
        <Plan element={element} />
        <Kontakt element={element} />
        <CookieConsent
          debug={true}
          style={{
            background: "#fff",
            color: "#000",
            textAlign: "left",
            width: "100vw",
          }}
          buttonStyle={{ background: "#001e6c", color: "#fff" }}
          buttonText={t("ok")}
          expires={90}
        >
          {t("cookies")}
        </CookieConsent>
      </div>
    </Suspense>
  );
}

export default App;
