import React from "react";
import "./home.css";
import start1 from "../../assets/start4.jpg";
import logo_granat from "../../assets/logo_bez_podpisu_white.png";
import { useTranslation } from "react-i18next";

const Home = ({ element }) => {
  const { t } = useTranslation(["home"]);

  return (
    <div
      ref={element}
      style={{ backgroundImage: `url(${start1})` }}
      className="home"
      id="home"
    >
      <img src={logo_granat} className="img" alt="logo"></img>
      <h1>{t("title")}</h1>
      <h2>{t("subtitle")}</h2>
    </div>
  );
};

export default Home;
