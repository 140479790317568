import { React } from "react";
import "./navbar.css";
import logo_small from "../../assets/logo_small.png";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Link } from "react-scroll";

const Navbar = ({ sticky }) => {
  const { i18n, t } = useTranslation();

  return (
    <nav className={sticky ? "navbar navbar-sticky" : "navbar"}>
      <div className="logo_container">
        {sticky ? <img src={logo_small} alt="logo" className="logo" /> : null}
      </div>
      <div className="links_container">
        {sticky ? (
          <Link
            activeClass="active"
            spy={true}
            smooth={true}
            offset={0}
            duration={1000}
            to="home"
            activeClassName="selected"
            className="link"
          >
            {t("menu_home")}
          </Link>
        ) : /*<a className="link" href="#home">
            {t("menu_home")}
          </a>*/
        null}
        {sticky ? (
          <Link
            activeClass="active"
            spy={true}
            smooth={true}
            offset={0}
            duration={1000}
            to="kaszubska_polana"
            activeClassName="selected"
            className="link"
          >
            {t("menu_polana")}
          </Link>
        ) : /*
          <a className="link" href="#kaszubska_polana">
            {t("menu_polana")}
          </a>*/
        null}
        {sticky ? (
          <Link
            activeClass="active"
            spy={true}
            smooth={true}
            offset={0}
            duration={1000}
            to="lokalizacja"
            activeClassName="selected"
            className="link"
          >
            {t("menu_localization")}
          </Link>
        ) : /*
          <a className="link" href="#lokalizacja">
            {t("menu_localization")}
          </a>*/
        null}
        {sticky ? (
          <Link
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={1000}
            to="plan"
            activeClassName="selected"
            className="link"
          >
            {t("menu_plan")}
          </Link>
        ) : /*
          <a className="link" href="#plan">
            {t("menu_plan")}
          </a>*/
        null}
        {sticky ? (
          <Link
            activeClass="active"
            spy={true}
            smooth={true}
            offset={0}
            duration={1000}
            to="kontakt"
            activeClassName="selected"
            className="link"
          >
            {t("menu_contact")}
          </Link>
        ) : /*
          <a className="link" href="#kontakt">
            {t("menu_contact")}
          </a>*/
        null}
        {sticky ? (
          <button
            className="link_lang"
            onClick={() => i18next.changeLanguage("en")}
          >
            {t("EN")}
          </button>
        ) : null}
        {sticky ? (
          <button
            className="link_lang"
            onClick={() => i18next.changeLanguage("pl")}
          >
            {t("PL")}
          </button>
        ) : null}
      </div>
    </nav>
  );
};

export default Navbar;
