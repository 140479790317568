import React, { Component } from "react";
import ReactPlayer from "react-player";
import "./video.css";

class Video extends Component {
  render() {
    return (
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player fixed-bottom"
          width="80vw"
          height="auto"
          url="video/film.mp4"
          controls={true}
          volume={0}
        />
      </div>
    );
  }
}

export default Video;
