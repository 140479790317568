import React from "react";
import "./lokalizacja.css";
import { useTranslation } from "react-i18next";
import divider from "../../assets/divider.png";
import photo4 from "../../assets/photo4.jpg";
import photo5 from "../../assets/photo5.jpg";
import bicycle from "../../assets/bicycle.png";
import golf from "../../assets/golf1.png";
import hiking from "../../assets/hiking.png";
import horse from "../../assets/riding.png";
import kayak from "../../assets/kayak.png";
import ski from "../../assets/ski.png";

class Icon extends React.Component {
  render() {
    return (
      <div className="div_icon_atr">
        <div className="icon_image">
          <div className="builder-image-sizer image-sizer">
            <img
              src={this.props.image}
              alt={this.props.title}
              className="image"
            />
          </div>
        </div>
        <div className="icon_title">
          <h2 className="icon_h2">{this.props.title}</h2>
        </div>
      </div>
    );
  }
}

const Lokalizacja = ({ element }) => {
  const { t } = useTranslation(["lokalizacja"]);

  return (
    <div id="lokalizacja" ref={element}>
      <div className="divider_container">
        <img className="divider" id="this_divider" src={divider} alt="" />
      </div>
      <h1 className="localization_banner">{t("localization_banner")}</h1>
      <div className="photo_container">
        <div className="photo_text">
          <h2 className="photo_header" id="odstep">
            {t("localization_text_header1")}
          </h2>
          <p className="text_photo">{t("localization_text1")}</p>
        </div>
        <div className="photos" id="map_div">
          <iframe
            title="map"
            className="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2258.910823861074!2d18.05687981587847!3d54.4026588802139!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb39d9fa0c7dc9cc3!2zNTTCsDI0JzA5LjYiTiAxOMKwMDMnMzIuNyJF!5e1!3m2!1spl!2spl!4v1664148874463!5m2!1spl!2spl"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            id="googlemap"
          ></iframe>
        </div>
      </div>
      <div className="photo_container">
        <img
          src={photo5}
          alt="Kaszubska Polana"
          className="photo_map"
          id="photo5"
        />
        <div className="photo_text">
          <h2 className="photo_header">{t("localization_text_header2")}</h2>
          <p className="text_photo">{t("localization_text2")}</p>
        </div>
      </div>
      <div>
        <img src={photo4} alt="Kaszubska Polana" className="photo4" />
      </div>
      {/* <div className="divider_container">
        <img className="divider" src={divider} alt="" />
      </div> */}
      <div className="attractions_header_container">
        <h2 className="attractions_header">{t("localization_header")}</h2>
      </div>
      <div className="attractions_container">
        <Icon image={horse} title={t("localization_horse")} />
        <Icon image={kayak} title={t("localization_kayak")} />
        <Icon image={bicycle} title={t("localization_bicycle")} />
      </div>
      <div className="attractions_container">
        <Icon image={hiking} title={t("localization_hiking")} />
        <Icon image={golf} title={t("localization_golf")} />
        <Icon image={ski} title={t("localization_ski")} />
      </div>
      <div className="divider_container">
        <img className="divider" src={divider} alt="" id="plan" />
      </div>
    </div>
  );
};

export default Lokalizacja;
