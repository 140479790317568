import React from "react";
import "./kontakt.css";
import { useTranslation } from "react-i18next";
import divider from "../../assets/divider.png";
import logo_granat from "../../assets/logo_bez_podpisu.png";
import mail from "../../assets/mail.png";
import cell from "../../assets/cell.png";

const Kontakt = ({ element }) => {
  const { t } = useTranslation(["kontakt"]);

  return (
    <div id="kontakt" ref={element}>
      <div className="divider_container">
        <img className="divider" src={divider} alt="" />
      </div>
      <h1 className="contact_banner">{t("contact_banner")}</h1>
      <div className="contact_container">
        <div className="data_container">
          {/* <h2>{t("contact_header")}</h2> */}
          <div className="info_container">
            <img src={mail} alt="mail" className="info_img" />
            <a href="mailto:info@kaszubskapolana.pl">
              <h3 className="contacts">info@kaszubskapolana.pl</h3>
            </a>
          </div>
          <div className="info_container">
            <img src={cell} alt="cell" className="info_img" />
            <h3 className="contacts">+48 665 130 000</h3>
          </div>
          <div className="info_container" id="last_contact">
            <img src={cell} alt="cell" className="info_img" />
            <h3 className="contacts">+48 514 000 750 (English)</h3>
          </div>
        </div>
        <div className="data_container">
          <img className="logo_img" src={logo_granat} alt="Kaszubska Polana" />
          <p className="logo_podpis">{t("title")}</p>
        </div>
      </div>
      <div className="contact_container" id="podpis"></div>
      <div className="credentials_container">
        <div className="credentials_column">
          <p className="credentials">{t("creds1")}</p>
          <p className="credentials">{t("creds2")}</p>
          <p className="credentials">{t("creds5")}</p>
        </div>
        <div className="credentials_column">
          <p className="credentials">{t("creds3")}</p>
          <p className="credentials">{t("creds4")}</p>
          <p className="credentials">{t("creds6")}</p>
        </div>
      </div>
    </div>
  );
};

export default Kontakt;
