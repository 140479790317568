import React from "react";
import { useTranslation } from "react-i18next";
import "./kaszubska_polana.css";
import fence from "../../assets/fence.png";
import city from "../../assets/city.png";
import trees from "../../assets/trees.png";
import photo1 from "../../assets/photo1.jpg";
import photo2 from "../../assets/photo2.png";
import photo3 from "../../assets/photo3.jpg";
import { Video } from "../../components";

class Icon extends React.Component {
  render() {
    return (
      <div className="div_icon">
        <div className="icon_image">
          <div className="builder-image-sizer image-sizer">
            <img
              src={this.props.image}
              alt={this.props.title}
              className="image"
            />
          </div>
        </div>
        <div className="icon_title">
          <h3 className="workaround1">{this.props.title1}</h3>
          <h3 className="workaround2">{this.props.title2}</h3>
        </div>
        <div className="icon_text">
          <p>{this.props.text}</p>
        </div>
      </div>
    );
  }
}

function Kaszubska_Polana({ element }) {
  const { t } = useTranslation(["polana"]);

  return (
    <div className="container" ref={element} id="kaszubska_polana">
      {/* <div>
        <h1 id="polana_baner">{t("polana_banner")}</h1>
      </div> */}

      <div className="icons_container">
        <Icon
          image={fence}
          title1={t("polana_privacy_header")}
          title2={t("polana_privacy_header1")}
          text={t("polana_privacy_text")}
        />
        <Icon
          image={city}
          title1={t("polana_tricity_header")}
          title2={t("polana_tricity_header1")}
          text={t("polana_tricity_text")}
        />
        <Icon
          image={trees}
          title1={t("polana_nature_header")}
          title2={t("polana_nature_header1")}
          text={t("polana_nature_text")}
        />
      </div>
      <div className="photo_container">
        <div className="photo_text">
          <h3 className="photo_header">{t("polana_photo1_header")}</h3>
          <p className="text_photo">{t("polana_photo1")}</p>
        </div>
        <img
          src={photo1}
          alt="Kaszubska Polana"
          className="photos"
          id="photo1"
        />
      </div>
      <div className="photo_container">
        <img
          src={photo2}
          alt="Kaszubska Polana"
          className="photos"
          id="photo2"
        />
        <div className="photo_text">
          <h3 className="photo_header">{t("polana_photo2_header")}</h3>
          <p className="text_photo" id="big">
            {t("polana_photo2")}
          </p>
        </div>
      </div>
      <div>
        <img src={photo3} alt="Kaszubska Polana" className="photo3" />
      </div>
      <div>
        <h2 className="film_text">{t("polana_film")}</h2>
        <Video />
        <p className="authors">{t("authors")}</p>
      </div>
    </div>
  );
}

export default Kaszubska_Polana;
